import { MainSubStructure, NormalProgrammeComponent } from '@/utils';
import { observer } from 'mobx-react';
import React from 'react';
import ModalAssemble from './components/modal/index.tsx';
import PositionCard from './components/positionCard.tsx';
import PositionManagementModel from './model.tsx';
@observer
export default class PositionManagement extends React.Component {
  constructor(prop) {
    super(prop);
    this.store = new PositionManagementModel();
  }
  public readonly store: PositionManagementModel;

  render() {
    const { normalProgramme, mainSubStructureModel } = this.store;
    return (
      <div className="h-screen flex flex-col">
        <NormalProgrammeComponent store={normalProgramme} />
        <div className="flex-1">
          <MainSubStructure store={mainSubStructureModel} />
        </div>
        <ModalAssemble store={this.store} />
        <PositionCard store={this.store.recommendedPositionModel} />
      </div>
    );
  }
}
