import { Card, Checkbox, Form, Radio, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { liveShapeDict, liveStyleDict, publicDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 直播间形式
 */
@inject('store')
@observer
class LiveBroadcasting extends React.Component<{ store?: AddModel }> {
  render() {
    return (
      <Card
        title="直播间形式"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            label="直播形式"
            name={'liveShape'}
            className={'mb-0'}
          >
            <Radio.Group options={liveShapeDict} />
          </Form.Item>
          <Form.Item
            label="是否露脸"
            name={'showFace'}
            className={'mb-0'}
          >
            <Radio.Group options={publicDict} />
          </Form.Item>
          <Form.Item
            label="直播间风格"
            name={'liveStyle'}
            className={'mb-0'}
          >
            <Checkbox.Group options={liveStyleDict} />
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default LiveBroadcasting;
