import { Card, Form, Radio, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { acceptNewbieDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 经验要求
 */
@inject('store')
@observer
class ExperienceRequirement extends React.Component<{ store?: AddModel }> {
  render() {
    return (
      <Card
        title="经验要求"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            label="是否接受小白"
            name={'acceptNewbie'}
            className={'mb-0'}
          >
            <Radio.Group options={acceptNewbieDict} />
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default ExperienceRequirement;
