import { Card, Checkbox, Form, Input, InputNumber, Radio, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  publicDict,
  requireAgeDict,
  requireDict,
  requireEducationDict,
  requireLanguageDict,
  requireLanguageTypeDict,
} from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 任职要求
 */
@inject('store')
@observer
class JobRequirements extends React.Component<{ store?: AddModel }> {
  render() {
    const { requireLanguage, setValue } = this.props.store;
    return (
      <Card
        title="任职要求"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            className={'mb-0'}
            label={'年龄要求'}
            shouldUpdate={true}
          >
            {({ getFieldValue }) => {
              const requireAge = getFieldValue('requireAge');
              return (
                <Space>
                  <Form.Item
                    name={'requireAge'}
                    noStyle={true}
                  >
                    <Radio.Group options={requireAgeDict} />
                  </Form.Item>
                  {requireAge === 1 ? (
                    <Space>
                      <Form.Item
                        name={'requireAgeStart'}
                        noStyle={true}
                        rules={[{ required: true, message: '请输入最小年龄' }]}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                        />
                      </Form.Item>
                      <div>-</div>
                      <Form.Item
                        name={'requireAgeEnd'}
                        noStyle={true}
                        rules={[{ required: true, message: '请输入最大年龄' }]}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                        />
                      </Form.Item>
                      <div>岁</div>
                    </Space>
                  ) : (
                    ''
                  )}
                </Space>
              );
            }}
          </Form.Item>
          <Form.Item
            className={'mb-0'}
            label={'身高要求'}
            shouldUpdate={true}
          >
            {({ getFieldValue }) => {
              const requireHeight = getFieldValue('requireHeight');
              return (
                <Space>
                  <Form.Item
                    name={'requireHeight'}
                    noStyle={true}
                  >
                    <Radio.Group options={requireDict} />
                  </Form.Item>
                  {requireHeight === 1 ? (
                    <Space>
                      <Form.Item
                        name={'requireHeightStart'}
                        noStyle={true}
                        rules={[{ required: true, message: '请输入最矮身高' }]}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                        />
                      </Form.Item>
                      <div>-</div>
                      <Form.Item
                        name={'requireHeightEnd'}
                        noStyle={true}
                        rules={[{ required: true, message: '请输入最高身高' }]}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                        />
                      </Form.Item>
                      <div>cm</div>
                    </Space>
                  ) : (
                    ''
                  )}
                </Space>
              );
            }}
          </Form.Item>
          <Form.Item
            className={'mb-0'}
            label={'体重要求'}
            shouldUpdate={true}
          >
            {({ getFieldValue }) => {
              const requireWeight = getFieldValue('requireWeight');
              return (
                <Space>
                  <Form.Item
                    name={'requireWeight'}
                    noStyle={true}
                  >
                    <Radio.Group options={requireDict} />
                  </Form.Item>
                  {requireWeight === 1 ? (
                    <Space>
                      <Form.Item
                        name={'requireWeightStart'}
                        noStyle={true}
                        rules={[{ required: true, message: '请输入最小体重' }]}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                        />
                      </Form.Item>
                      <div>-</div>
                      <Form.Item
                        name={'requireWeightEnd'}
                        noStyle={true}
                        rules={[{ required: true, message: '请输入最大体重' }]}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                        />
                      </Form.Item>
                      <div>kg</div>
                    </Space>
                  ) : (
                    ''
                  )}
                </Space>
              );
            }}
          </Form.Item>
          <Form.Item
            className={'mb-0'}
            label={'学历要求'}
            name={'requireEducation'}
          >
            <Radio.Group options={requireEducationDict} />
          </Form.Item>

          <Form.Item
            className={'mb-0'}
            label={'是否有语言要求'}
            name={'requireLanguage'}
          >
            <Radio.Group
              onChange={(e) => {
                setValue('requireLanguage', e.target.value);
              }}
              options={requireLanguageDict}
            />
          </Form.Item>
          {requireLanguage === 1 ? (
            <Form.Item
              className={'mb-0'}
              label={'选择语言'}
              required={true}
              shouldUpdate={true}
            >
              {({ getFieldValue }) => {
                const requireLanguageType = getFieldValue('requireLanguageType');
                return (
                  <Space>
                    <Form.Item
                      noStyle={true}
                      rules={[{ required: true, message: '请选择语言' }]}
                      name={'requireLanguageType'}
                    >
                      <Checkbox.Group options={requireLanguageTypeDict} />
                    </Form.Item>
                    {(requireLanguageType || []).includes(3) ? (
                      <Form.Item
                        noStyle={true}
                        name={'languageName'}
                      >
                        <Input
                          autoComplete="off"
                          maxLength={10}
                        />
                      </Form.Item>
                    ) : undefined}
                  </Space>
                );
              }}
            </Form.Item>
          ) : undefined}
          <Form.Item
            className={'mb-0'}
            label={'专业要求'}
            shouldUpdate={true}
          >
            {({ getFieldValue }) => {
              const requireMajor = getFieldValue('requireMajor');
              return (
                <Space>
                  <Form.Item
                    name={'requireMajor'}
                    noStyle={true}
                  >
                    <Radio.Group options={requireDict} />
                  </Form.Item>
                  {requireMajor === 1 ? (
                    <Space>
                      <Form.Item
                        name={'requireMajorType'}
                        noStyle={true}
                        rules={[{ required: true, message: '请填写专业' }]}
                      >
                        <Input
                          autoComplete="off"
                          maxLength={10}
                        />
                      </Form.Item>
                    </Space>
                  ) : (
                    ''
                  )}
                </Space>
              );
            }}
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default JobRequirements;
