import { Card, Form, Input, Radio, Select, Space } from 'antd';
import { Provider, inject, observer } from 'mobx-react';
import React from 'react';
import { isKeepOfficeDict, requireSexDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';
/**
 * 职位信息
 */
@inject('store')
@observer
class JobInformation extends React.Component<{ store?: AddModel }> {
  render() {
    const { formRef, parentLiveTypeDict, childLiveTypeDict, getChildLiveTypeList } = this.props.store;
    return (
      <Card
        title="职位信息"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            label="直播类目"
            className={'mb-0'}
            required={true}
          >
            <Space
              className={`w-full ${styles.live}`}
              direction={'horizontal'}
            >
              <Form.Item
                noStyle={true}
                rules={[{ required: true, message: '请选择一级分类' }]}
                name="liveTypeParentId"
              >
                <Select
                  className={'!w-full'}
                  options={parentLiveTypeDict}
                  onChange={(value) => {
                    getChildLiveTypeList(value as string);
                    formRef.current.setFieldValue('liveTypeChildId', undefined);
                  }}
                  placeholder={'请选择一级分类'}
                />
              </Form.Item>
              <Form.Item
                noStyle={true}
                rules={[{ required: true, message: '请选择二级分类' }]}
                name={'liveTypeChildId'}
              >
                <Select
                  className={'!w-full'}
                  options={childLiveTypeDict}
                  placeholder={'请选择二级分类'}
                />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            label="职位名称"
            name={'name'}
            rules={[
              {
                required: true,
              },
            ]}
            className={'mb-0'}
          >
            <Input
              autoComplete="off"
              maxLength={20}
              placeholder={'请输入'}
            />
          </Form.Item>
          <Form.Item
            label="主播性别"
            rules={[
              {
                required: true,
              },
            ]}
            name={'requireSex'}
            className={'mb-0'}
          >
            <Radio.Group options={requireSexDict} />
          </Form.Item>
          <Form.Item
            label="是否需要坐班"
            name={'isKeepOffice'}
            rules={[{ required: true }]}
            className={'mb-0'}
          >
            <Radio.Group options={isKeepOfficeDict} />
          </Form.Item>
          <Form.Item
            label="岗位描述"
            name={'positionDescribe'}
            className={'mb-2'}
            rules={[{ required: true }]}
          >
            <Input.TextArea
              maxLength={500}
              autoComplete="off"
              showCount={true}
              autoSize={{ minRows: 8, maxRows: 8 }}
            />
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default JobInformation;
