import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Card, Form, Input, InputNumber, Radio, Select, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  acceptNewbieDict,
  accountTypeDict,
  flowTypeDict,
  groupTypeDict,
  needCoordinateWorkDict,
  publicDict,
} from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 岗位配置
 */
@inject('store')
@observer
class PositionAllocation extends React.Component<{ store?: AddModel }> {
  render() {
    const { type, platformTypeDict, setValue, needCoordinateWork } = this.props.store;
    return (
      <Card
        title="岗位配置"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            name={'flowType'}
            className={'mb-0'}
            label={'流量'}
          >
            <Radio.Group options={flowTypeDict} />
          </Form.Item>
          <Form.Item
            name={'groupType'}
            className={'mb-0'}
            label={'团队类型'}
          >
            <Radio.Group options={groupTypeDict} />
          </Form.Item>
          <Form.Item
            name={'hasOperation'}
            className={'mb-0'}
            label={'是否有运营'}
          >
            <Radio.Group options={publicDict} />
          </Form.Item>
          <Form.Item
            name={'needCoordinateWork'}
            className={'mb-0'}
            label={'是否需配合做其它工作'}
          >
            <Radio.Group
              onChange={(e) => {
                setValue('needCoordinateWork', e.target.value);
              }}
              options={needCoordinateWorkDict}
            />
          </Form.Item>
          {needCoordinateWork ? (
            <Form.List
              initialValue={['']}
              name="coordinateWorkType"
            >
              {(fields, { add, remove }, { errors }) => (
                <Space
                  className={'w-full'}
                  direction={'vertical'}
                >
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Form.Item
                      {...(index !== 0 ? { wrapperCol: { offset: 3 } } : undefined)}
                      label={index === 0 ? '工作类型' : ''}
                      required={false}
                      className={'mb-0'}
                      key={key}
                    >
                      <Space className={'w-full'}>
                        <Form.Item
                          {...restField}
                          name={[name]}
                          validateTrigger={['onChange', 'onBlur']}
                          noStyle={true}
                        >
                          <Input
                            autoComplete="off"
                            placeholder={'请输入工作类型'}
                          />
                        </Form.Item>
                        {[1, 3].includes(type) && fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(name)}
                          />
                        ) : null}
                        {[1, 3].includes(type) && fields.length - 1 === index ? (
                          <PlusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => add()}
                          />
                        ) : null}
                      </Space>
                    </Form.Item>
                  ))}
                </Space>
              )}
            </Form.List>
          ) : undefined}
        </Space>
      </Card>
    );
  }
}
export default PositionAllocation;
