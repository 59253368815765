import { Card, Checkbox, Form, Input, InputNumber, Radio, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  acceptNewbieDict,
  holidayRestTypeDict,
  prepareTypeDict,
  publicDict,
  restFixedDict,
  restTypeDict,
  weeksDict,
} from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 排班形式
 */
@inject('store')
@observer
class TypesettingForm extends React.Component<{ store?: AddModel }> {
  render() {
    const { setValue, restType, restFixed } = this.props.store;
    return (
      <Card
        title="排班形式"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            label="是否有凌晨播"
            name={'hasEveningWork'}
            className={'mb-0'}
          >
            <Radio.Group options={publicDict} />
          </Form.Item>
          <Form.Item
            label="班次"
            name={'prepareType'}
            className={'mb-0'}
          >
            <Radio.Group options={prepareTypeDict} />
          </Form.Item>
          <Form.Item
            label="休息模式"
            name={'restType'}
            className={'mb-0'}
          >
            <Radio.Group
              onChange={(e) => {
                setValue('restType', e.target.value);
              }}
              options={restTypeDict}
            />
          </Form.Item>
          {restType === 2 ? (
            <Form.Item
              label="是否固定休"
              name={'restFixed'}
              className={'mb-0'}
            >
              <Radio.Group
                onChange={(e) => {
                  setValue('restFixed', e.target.value);
                }}
                options={restFixedDict}
              />
            </Form.Item>
          ) : undefined}
          {restType === 1 || (restType === 2 && restFixed === 0) ? (
            <Form.Item
              label=""
              wrapperCol={{ offset: 3 }}
              name={'restDays'}
              className={'mb-0'}
            >
              <InputNumber
                min={0}
                maxLength={4}
                precision={0}
                className={'w-50'}
                placeholder={'请输入休息天数'}
              />
            </Form.Item>
          ) : undefined}
          {restType === 2 && restFixed === 1 ? (
            <Form.Item
              label=""
              wrapperCol={{ offset: 3 }}
              name={'restWeekDays'}
              className={'mb-0'}
            >
              <Checkbox.Group options={weeksDict} />
            </Form.Item>
          ) : undefined}

          <Form.Item
            label="节假日"
            name={'holidayRestType'}
            className={'mb-0'}
          >
            <Radio.Group options={holidayRestTypeDict} />
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default TypesettingForm;
