import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Card, Checkbox, Form, Input, InputNumber, Radio, Select, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { acceptNewbieDict, requireAssociateLiveStyleDict, requireAssociatePerformanceDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 过往经验
 */
@inject('store')
@observer
class PastExperience extends React.Component<{ store?: AddModel }> {
  render() {
    const { platformTypeDict, liveTypeDict, type, requireAssociatePerformance, setValue } = this.props.store;
    return (
      <Card
        title="过往经验"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            wrapperCol={{ span: 20 }}
            className={'mb-0'}
            label={'过往工作行业'}
            name={'requireAssociateExperience'}
          >
            <Select
              placeholder={'请选择过往工作行业'}
              fieldNames={{ label: 'name', value: 'id', options: 'childrenList' }}
              options={liveTypeDict}
              mode="multiple"
            />
          </Form.Item>
          <Form.List
            initialValue={['']}
            name="functionalExperience"
          >
            {(fields, { add, remove }, { errors }) => (
              <Space
                className={'w-full'}
                direction={'vertical'}
              >
                {fields.map(({ key, name, ...restField }, index) => (
                  <Form.Item
                    {...(index !== 0 ? { wrapperCol: { offset: 3 } } : undefined)}
                    label={index === 0 ? '过往职能经验' : ''}
                    required={false}
                    className={'mb-0'}
                    key={key}
                  >
                    <Space className={'w-full'}>
                      <Form.Item
                        {...restField}
                        name={[name]}
                        validateTrigger={['onChange', 'onBlur']}
                        noStyle={true}
                      >
                        <Input
                          autoComplete="off"
                          placeholder={'请输入过职能经验'}
                        />
                      </Form.Item>
                      {[1, 3].includes(type) && fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                        />
                      ) : null}
                      {[1, 3].includes(type) && fields.length - 1 === index ? (
                        <PlusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => add()}
                        />
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
              </Space>
            )}
          </Form.List>
          <Form.Item
            label="过往业绩"
            name={'requireAssociatePerformance'}
            className={'mb-0'}
          >
            <Radio.Group
              onChange={(e) => {
                setValue('requireAssociatePerformance', e.target.value);
              }}
              options={requireAssociatePerformanceDict}
            />
          </Form.Item>
          {requireAssociatePerformance === 1 ? (
            <Space
              className={'w-full'}
              direction="vertical"
            >
              <Form.Item
                label="单场最高销售额"
                className={'mb-0'}
              >
                <Space>
                  <Form.Item
                    name={'requireSalesVolume'}
                    noStyle={true}
                  >
                    <InputNumber
                      className={'!w-40'}
                      min={0}
                      maxLength={10}
                      precision={0}
                    />
                  </Form.Item>
                  <div>元</div>
                </Space>
              </Form.Item>
              <Form.Item
                label="总销售额"
                className={'mb-0'}
              >
                <Space>
                  <Form.Item
                    name={'requireTotalSalesVolume'}
                    noStyle={true}
                  >
                    <InputNumber
                      className={'!w-40'}
                      min={0}
                      maxLength={10}
                      precision={0}
                    />
                  </Form.Item>
                  <div>元</div>
                </Space>
              </Form.Item>
              <Form.Item
                label="单场最高同时在线人数"
                className={'mb-0'}
              >
                <Space>
                  <Form.Item
                    name={'requireOnlineNum'}
                    noStyle={true}
                  >
                    <InputNumber
                      className={'!w-40'}
                      min={0}
                      maxLength={10}
                      precision={0}
                    />
                  </Form.Item>
                  <div>人</div>
                </Space>
              </Form.Item>
            </Space>
          ) : undefined}
          <Form.Item
            className={'mb-0'}
            label={'过往平台'}
            name={'requireAssociatePlatform'}
          >
            <Checkbox.Group options={platformTypeDict} />
          </Form.Item>
          <Form.Item
            className={'mb-0'}
            label={'过往直播风格'}
            name={'requireAssociateLiveStyle'}
          >
            <Checkbox.Group options={requireAssociateLiveStyleDict} />
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default PastExperience;
