import type ParentStore from '@/pages/positionManagement/model.tsx';
import { type BaseData, MainSubStructureModel, getWxUrl, request } from '@/utils';
import { Popover, message } from 'antd';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { action, observable, runInAction } from 'mobx';
import type { PositionDetailVO } from './components/type';
import type { SysPositionVO } from './interface';

export default class RecommendedPositionModel {
  private isDownloading: boolean;
  @observable public rowData: PositionDetailVO;
  @observable public wxCode: string;
  constructor(parentStore: ParentStore) {
    this.parentStore = parentStore;
    this.initMainSubStructureModel();
  }
  public parentStore: ParentStore;

  @observable open = false;
  @observable loading = false;
  @observable public userName: string; //主播昵称
  @observable public cityName: string; //工作城市
  @observable public mainSubStructureModel: MainSubStructureModel;
  public positionIds: string[] = [];
  @action
  public setValue = (key: string, value: string) => {
    this[key] = value;
  };
  @action
  public onOpen = (positionIds: string[]) => {
    this.open = true;
    this.positionIds = positionIds || [];
    this.onQuery();
  };
  @action
  public onClose = () => {
    this.open = false;
    setTimeout(() => {
      runInAction(() => {
        this.loading = false;
        this.positionIds = [];
        this.onReset();
        this.mainSubStructureModel.gridModel?.clearToOriginal();
      });
    }, 100);
  };
  /**
   * 立即推荐
   */
  @action
  public recommendPositionBatch = () => {
    try {
      this.loading = true;
      const selectedIds = Array.from(this.mainSubStructureModel.gridModel.selectedIds);
      if (!selectedIds?.length) {
        message.warning('请选择需要推荐的主播！');
        return;
      }
      request<BaseData<string>>({
        method: 'POST',
        url: '/pbb/platform/sys/position/recommendPositionBatch',
        data: {
          positionIds: this.positionIds,
          brokerUserList: this.mainSubStructureModel.gridModel.selectRows.map((item) => {
            return {
              brokerId: item.brokerId,
              userId: item.userId,
            };
          }),
        },
      }).then((req) => {
        message.success(req.data);
        this.onClose();
        this.parentStore.mainSubStructureModel.onQuery();
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  };
  @action
  public onQuery = () => {
    this.mainSubStructureModel.onQuery();
  };
  @action
  public onReset = () => {
    this.cityName = undefined;
    this.userName = undefined;
  };
  @action
  public initMainSubStructureModel = () => {
    const mainSubStructureParams = {
      grid: {
        columns: [
          {
            key: 'userNickName',
            name: '主播姓名',
          },
          {
            key: 'age',
            name: '年龄',
            formatter: ({ row }) => {
              return row.age || '-';
            },
          },
          {
            key: 'cityName',
            name: '工作城市',
            formatter: ({ row }) => {
              return row.cityName || '-';
            },
          },
          {
            key: 'services',
            name: '服务名称',
            formatter: ({ row }) => {
              return (
                <Popover
                  title={'服务名称'}
                  content={
                    <div>
                      {row?.services?.length
                        ? (row?.services || [])?.map((item, index) => {
                            return <div key={`${index.toString()}services`}>{item || '-'}</div>;
                          })
                        : '-'}
                    </div>
                  }
                >
                  {row?.services?.length ? row?.services?.join(',') : '-'}
                </Popover>
              );
            },
          },
          {
            key: 'signUpCount',
            name: '报名职位数',
          },
          {
            key: 'interviewedCount',
            name: '到面次数',
          },
        ].map((v) => ({
          resizable: true,
          sortable: false,
          ...v,
        })),
        showRefresh: false,
        primaryKeyField: 'userId',
        sortByLocal: false,
        showCheckBox: true,
        showEmpty: false,
        showPager: true,
        showPagination: true,
        showGridOrderNo: true,
        showSelectedTotal: false,
      },
      hiddenSubTable: true,
      api: {
        onQuery: (params) => {
          const { filterParams, ...rest } = params;
          const postParams = {
            ...filterParams,
            ...rest,
          };
          return request({
            method: 'POST',
            url: '/pbb/platform/sys/position/getRecommendUserBrokerList',
            data: { ...postParams, cityName: this.cityName?.trim(), userName: this.userName?.trim() },
          });
        },
      },
    };
    this.mainSubStructureModel = new MainSubStructureModel(mainSubStructureParams);
  };

  @action downloadPositionCard = async (row: SysPositionVO) => {
    try {
      if (this.isDownloading) {
        message.warning('处理中,请稍后');
        return;
      }
      this.isDownloading = true;

      await this.getPositionInfo(String(row.id));

      this.wxCode = await getWxUrl({
        channel: 1,
        positionId: row.id,
      });
      setTimeout(() => {
        this.downPic('positionCard', `职位卡片-${row.name}`);
      }, 10);
    } catch (error) {
    } finally {
      this.isDownloading = false;
    }
  };

  // 下载图片
  @action public downPic = async (id: string, name: string) => {
    const canvas2 = document.createElement('canvas');
    const _element = document.getElementById(`${id}Element`);

    const w = Number.parseInt(window.getComputedStyle(_element).width, 10);
    const h = Number.parseInt(window.getComputedStyle(_element).height, 10);

    canvas2.width = w * 4;
    canvas2.height = h * 4;
    const context = canvas2.getContext('2d');
    context.imageSmoothingEnabled = false;
    context.scale(4, 4);

    const canvas = await html2canvas(_element, {
      canvas: canvas2,
      scale: 1,
      useCORS: true,
    });
    canvas.toBlob((blob) => {
      FileSaver.saveAs(blob, name);
    });
  };

  // 获取职位信息
  @action public getPositionInfo = async (id: string) => {
    const req = await request<BaseData<{ [key: string]: any }>>({
      method: 'POST',
      url: '/pbb/platform/sys/position/getPositionDetailById',
      data: {
        id: id,
      },
    });
    this.rowData = req.data;
  };
}
