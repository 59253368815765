import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Card, Form, Input, InputNumber, Radio, Select, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { acceptNewbieDict, accountTypeDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 账号信息
 */
@inject('store')
@observer
class AccountInformation extends React.Component<{ store?: AddModel }> {
  render() {
    const { platformTypeDict, type } = this.props.store;
    return (
      <Card
        title="账号信息"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.List
            initialValue={[{}]}
            name="platformList"
          >
            {(fields, { add, remove }, { errors }) => (
              <Space
                className={'w-full'}
                direction={'vertical'}
              >
                {fields.map(({ key, name, ...restField }, index) => (
                  <Form.Item
                    {...(index !== 0 ? { wrapperCol: { offset: 3 } } : undefined)}
                    label={index === 0 ? '直播平台账号' : ''}
                    required={false}
                    className={'mb-0'}
                    key={key}
                  >
                    <Space className={'w-full'}>
                      <Form.Item
                        {...restField}
                        name={[name, 'platformValue']}
                        validateTrigger={['onChange', 'onBlur']}
                        noStyle={true}
                      >
                        <Select
                          options={platformTypeDict}
                          placeholder={'请选择平台'}
                          className={'!w-40'}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'account']}
                        validateTrigger={['onChange', 'onBlur']}
                        noStyle={true}
                      >
                        <Input
                          autoComplete="off"
                          placeholder={'请输入账号'}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'accountType']}
                        validateTrigger={['onChange', 'onBlur']}
                        noStyle={true}
                      >
                        <Radio.Group options={accountTypeDict} />
                      </Form.Item>
                      {[1, 3].includes(type) && fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                        />
                      ) : null}
                      {[1, 3].includes(type) && fields.length - 1 === index ? (
                        <PlusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => add()}
                        />
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
              </Space>
            )}
          </Form.List>
        </Space>
      </Card>
    );
  }
}
export default AccountInformation;
