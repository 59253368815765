import { Card, Checkbox, Form, Radio, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { acceptNewbieDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 福利待遇
 */
@inject('store')
@observer
class WelfareTreatment extends React.Component<{ store?: AddModel }> {
  render() {
    const { insureListDict, salaryListDict, subsidyListDict, vacationListDict } = this.props.store;
    return (
      <Card
        title="福利待遇"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            label="保险"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name={'insureList'}
            className={'mb-0'}
          >
            <Checkbox.Group options={insureListDict} />
          </Form.Item>
          <Form.Item
            label="薪资期权"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name={'salaryList'}
            className={'mb-0'}
          >
            <Checkbox.Group options={salaryListDict} />
          </Form.Item>
          <Form.Item
            label="度假休假"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name={'vacation'}
            className={'mb-0'}
          >
            <Checkbox.Group options={vacationListDict} />
          </Form.Item>
          <Form.Item
            label="生活补贴"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name={'subsidyList'}
            className={'mb-0'}
          >
            <Checkbox.Group options={subsidyListDict} />
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default WelfareTreatment;
