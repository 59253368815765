import AddDrawer from '@/pages/positionManagement/components/add';
import { MainSubStructure, getWxUrl } from '@/utils';
import { Button, Input, Modal, Radio, Space, message } from 'antd';
import { Provider, inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import type PositionManagementModel from '../../model.tsx';

@observer
export default class ModalAssemble extends React.Component<{ store: PositionManagementModel }> {
  render() {
    return (
      <>
        <Provider store={this.props.store}>
          <AuditPositionModal />
          <NumberInformation />
          <RecommendedPosition />
          <AddDrawer />
        </Provider>
      </>
    );
  }
}
const RecommendedPosition = inject('store')(
  observer((props: { store?: PositionManagementModel }) => {
    const {
      open,
      loading,
      recommendPositionBatch,
      onClose,
      userName,
      setValue,
      onQuery,
      onReset,
      cityName,
      mainSubStructureModel,
    } = props.store.recommendedPositionModel;
    return (
      <Modal
        loading={loading}
        open={open}
        okText={'立即推荐'}
        onOk={recommendPositionBatch}
        title={'推荐职位'}
        width={'60%'}
        onCancel={onClose}
      >
        <div>
          {/*<div className={'flex flex-row items-center'}>*/}
          {/*  <span className={'w-[66px]'}>职位链接：</span>*/}
          {/*  <Space>*/}
          {/*    <span>{wxUrl || '-'}</span>*/}
          {/*    <Button*/}
          {/*      onClick={() => {*/}
          {/*        copyToClipboard(wxUrl);*/}
          {/*        message.success('复制链接成功');*/}
          {/*      }}*/}
          {/*      type={'primary'}*/}
          {/*    >*/}
          {/*      复制链接*/}
          {/*    </Button>*/}
          {/*  </Space>*/}
          {/*</div>*/}
          <Space className="flex flex-row items-center my-4">
            <div className={'flex flex-row items-center'}>
              <span className={'flex-shrink-0 mr-[8px]'}>主播姓名</span>
              <Input
                placeholder={'请输入'}
                value={userName}
                onPressEnter={() => {
                  onQuery();
                }}
                onChange={(e) => {
                  setValue('userName', e.target.value);
                }}
              />
            </div>
            <div className={'flex flex-row items-center'}>
              <span className={'flex-shrink-0 mr-[8px]'}>工作城市</span>
              <Input
                placeholder={'请输入'}
                value={cityName}
                onPressEnter={() => {
                  onQuery();
                }}
                onChange={(e) => {
                  setValue('cityName', e.target.value);
                }}
              />
            </div>
            <Space>
              <Button
                type="primary"
                onClick={onQuery}
              >
                查询
              </Button>
              <Button onClick={onReset}>重置</Button>
            </Space>
          </Space>
          <div className={'h-[60vh]'}>
            <MainSubStructure store={mainSubStructureModel} />
          </div>
        </div>
      </Modal>
    );
  }),
);
/**
 * 邀约信息、推荐信息、报名信息弹窗
 */
const NumberInformation = inject('store')(
  observer((props: { store?: PositionManagementModel }) => {
    const {
      informationOpen,
      getTitle,
      name,
      brokerName,
      setBrokerName,
      numberOfPeople,
      onCloseModal,
      status,
      getNumText,
      mainSubStructureModel,
    } = props.store.informationPopupModel;
    return (
      <Modal
        open={informationOpen}
        title={getTitle}
        footer={null}
        width={'60%'}
        onCancel={onCloseModal}
      >
        <div>
          <div>
            <div className={'flex flex-row items-center'}>
              <div className={'flex flex-row items-center'}>
                <span className={'w-[66px]'}>职位名称：</span>
                <span>{name}</span>
              </div>
              {status === 1 ? (
                <div className="ml-36">
                  <span>经纪人：</span>
                  <Space>
                    <Input
                      value={brokerName}
                      onChange={(event) => {
                        setBrokerName(event.target.value);
                      }}
                      onPressEnter={() => {
                        mainSubStructureModel.onQuery();
                      }}
                      placeholder={'请输入'}
                    />
                    <Button
                      onClick={() => {
                        mainSubStructureModel.onQuery();
                      }}
                      type="primary"
                    >
                      查询
                    </Button>
                  </Space>
                </div>
              ) : undefined}
            </div>
            <div className={'flex flex-row items-center'}>
              <span className={'w-[66px]'}>{getNumText}：</span>
              <span>{numberOfPeople}人</span>
            </div>
          </div>
          <div className={'h-[60vh]'}>
            <MainSubStructure store={mainSubStructureModel} />
          </div>
        </div>
      </Modal>
    );
  }),
);
/**
 *  审核弹窗
 */
const AuditPositionModal = inject('store')(
  observer((props: { store?: PositionManagementModel }) => {
    const [status, setStatus] = useState(1);
    const { auditPositionOpen, onAuditPosition, setAuditPositionOpen } = props.store;
    const [auditReason, setAuditReason] = useState('');
    const onCancel = () => {
      setStatus(1);
      setAuditReason('');
    };
    return (
      <Modal
        title="审核结果"
        centered={true}
        open={auditPositionOpen}
        onOk={async () => {
          if (status === 1 || (status === 2 && auditReason)) {
            const reqStatus = await onAuditPosition({
              auditReason: auditReason || undefined,
              status,
            });
            if (reqStatus) {
              onCancel();
            }
            return;
          }
          message.warning('请填写未通过原因');
        }}
        onCancel={() => {
          onCancel();
          setAuditPositionOpen();
        }}
      >
        <div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center">
              <span className={'text-[red] mr-1'}>*</span>
              <span>是否通过</span>
            </div>
            <Radio.Group
              className="ml-10"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              options={[
                {
                  label: '通过',
                  value: 1,
                },
                {
                  label: '未通过',
                  value: 2,
                },
              ]}
            />
          </div>
          <div className={`mt-2 ${status === 1 ? 'hidden' : ''}`}>
            <div className="flex flex-row items-center">
              <span className={'text-[red] mr-1'}>*</span>
              <span>未通过原因</span>
            </div>
            <Input.TextArea
              value={auditReason}
              onChange={(e: any) => {
                setAuditReason(e.target.value);
              }}
              placeholder={'请输入未通过原因'}
              autoSize={{
                minRows: 6,
                maxRows: 6,
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }),
);
