import { type IMainSubStructureModel, MainSubStructureModel, request } from '@/utils';
import { Button, Image } from 'antd';
import { action, computed, observable, runInAction } from 'mobx';
import React from 'react';
import type ParentStore from './model.tsx';

export default class InformationPopupModel {
  constructor(parentStore: ParentStore) {
    this.parentStore = parentStore;
  }
  public parentStore: ParentStore;
  @observable public status: 1 | 2 | 3; //1: 推荐信息   2: 报名信息  3: 邀约信息
  @observable public informationOpen = false;
  @observable public mainSubStructureModel: MainSubStructureModel;
  @observable public numberOfPeople = 0; //人数
  @observable public name: string; //职位名称
  @observable public brokerName: string;
  public positionId: string;
  public companyName: string;
  @action
  public setBrokerName = (brokerName: string) => {
    this.brokerName = brokerName;
  };
  @action
  public onOpen = (
    status: 1 | 2 | 3,
    name: string,
    numberOfPeople: number,
    positionId: string,
    companyName: string,
  ) => {
    this.positionId = positionId;
    this.status = status;
    this.initMainSubStructureModel();
    this.numberOfPeople = numberOfPeople || 0;
    this.name = name;
    this.informationOpen = true;
    this.companyName = companyName;
  };
  @action
  public onCloseModal = () => {
    this.informationOpen = false;
    this.status = undefined;
    this.name = undefined;
    this.numberOfPeople = 0;
    this.mainSubStructureModel = undefined;
    this.positionId = undefined;
    this.brokerName = undefined;
    this.companyName = undefined;
  };
  @computed
  public get getNumText(): string {
    let text: string;
    switch (this.status) {
      case 1: {
        text = '推荐人数';
        break;
      }
      case 2: {
        text = '报名人数';
        break;
      }
      case 3: {
        text = '邀约人数';
        break;
      }
    }
    return text;
  }
  @computed
  public get getTitle(): string {
    let title = '';
    switch (this.status) {
      case 1: {
        title = '推荐信息';
        break;
      }
      case 2: {
        title = '报名信息';
        break;
      }
      case 3: {
        title = '邀约信息';
        break;
      }
    }
    return title;
  }
  @action
  public initMainSubStructureModel = () => {
    let mainSubStructureParams: IMainSubStructureModel;

    switch (this.status) {
      case 1: {
        mainSubStructureParams = {
          grid: {
            columns: [
              {
                key: 'operate',
                name: '操作',
                frozen: true,
                width: 100,
                formatter: ({ row }) => (
                  <div>
                    <Button
                      onClick={() => {
                        window.top.RingPermission.openTab(
                          `/pbb-pc-management/employment/index?positionName=${this.name}&companyName=${this.companyName}&userName=${row.userName}`,
                          '53',
                          '求职招聘',
                        );
                      }}
                      type="link"
                    >
                      详情
                    </Button>
                  </div>
                ),
              },
              {
                name: '推荐时间',
                minWidth: 180,

                key: 'recommendTime',
              },

              {
                key: 'userName',
                name: '主播姓名',
              },
              {
                key: 'brokerName',
                name: '经纪人',
              },
              {
                key: 'status',
                name: '状态',
              },
            ].map((v) => ({
              resizable: true,
              sortable: false,
              ...v,
            })),
            showRefresh: false,
            primaryKeyField: 'recruitId',
            sortByLocal: false,
            pageSize: 20,
            pageSizeOptions: ['20'],
            showCheckBox: false,
            showEmpty: false,
            showPager: true,
            showPagination: true,
            showGridOrderNo: true,
            showSelectedTotal: false,
          },
          hiddenSubTable: true,
          api: {
            onQuery: (params) => {
              const { filterParams, ...rest } = params;
              const postParams = {
                ...filterParams,
                ...rest,
              };
              return request({
                method: 'POST',
                url: '/pbb/platform/sys/position/getRecommendInfo',
                data: { ...postParams, positionId: this.positionId, brokerName: this.brokerName?.trim() },
              }).then((req: any) => {
                runInAction(() => {
                  this.numberOfPeople = req?.data?.total;
                });
                return req;
              });
            },
          },
        };
        break;
      }
      case 2: {
        mainSubStructureParams = {
          grid: {
            columns: [
              {
                key: 'operate',
                name: '操作',
                frozen: true,
                width: 100,
                formatter: ({ row }) => (
                  <div>
                    <Button
                      onClick={() => {
                        window.top.RingPermission.openTab(
                          `/pbb-pc-management/employment/index?positionName=${this.name}&companyName=${this.companyName}&userName=${row.userName}`,
                          '53',
                          '求职招聘',
                        );
                      }}
                      type="link"
                    >
                      详情
                    </Button>
                  </div>
                ),
              },
              {
                name: '报名时间',
                minWidth: 180,
                key: 'time',
              },

              {
                key: 'userName',
                name: '主播姓名',
              },
              {
                key: 'status',
                name: '状态',
              },
            ].map((v) => ({
              resizable: true,
              sortable: false,
              ...v,
            })),
            showRefresh: false,
            primaryKeyField: 'recruitId',
            sortByLocal: false,
            pageSize: 20,
            pageSizeOptions: ['20'],
            showCheckBox: false,
            showEmpty: false,
            showPager: true,
            showPagination: true,
            showGridOrderNo: true,
            showSelectedTotal: false,
          },
          hiddenSubTable: true,
          api: {
            onQuery: (params) => {
              const { filterParams, ...rest } = params;
              const postParams = {
                ...filterParams,
                ...rest,
              };
              return request({
                method: 'POST',
                url: '/pbb/platform/sys/position/getSignUpInfo',
                data: { ...postParams, positionId: this.positionId },
              }).then((req: any) => {
                runInAction(() => {
                  this.numberOfPeople = req?.data?.total;
                });
                return req;
              });
            },
          },
        };
        break;
      }
      case 3: {
        mainSubStructureParams = {
          grid: {
            columns: [
              {
                key: 'operate',
                name: '操作',
                frozen: true,
                width: 100,
                formatter: ({ row }) => (
                  <div>
                    <Button
                      onClick={() => {
                        window.top.RingPermission.openTab(
                          `/pbb-pc-management/employment/index?positionName=${this.name}&companyName=${this.companyName}&userName=${row.userName}`,
                          '53',
                          '求职招聘',
                        );
                      }}
                      type="link"
                    >
                      详情
                    </Button>
                  </div>
                ),
              },
              {
                name: '邀约时间',
                minWidth: 180,
                key: 'time',
              },

              {
                key: 'userName',
                name: '主播姓名',
              },
              {
                key: 'status',
                name: '状态',
              },
            ].map((v) => ({
              resizable: true,
              sortable: false,
              ...v,
            })),
            showRefresh: false,
            primaryKeyField: 'recruitId',
            sortByLocal: false,
            pageSize: 20,
            pageSizeOptions: ['20'],
            showCheckBox: false,
            showEmpty: false,
            showPager: true,
            showPagination: true,
            showGridOrderNo: true,
            showSelectedTotal: false,
          },
          hiddenSubTable: true,
          api: {
            onQuery: (params) => {
              const { filterParams, ...rest } = params;
              const postParams = {
                ...filterParams,
                ...rest,
              };
              return request({
                method: 'POST',
                url: '/pbb/platform/sys/position/getInviteInfo',
                data: { ...postParams, positionId: this.positionId },
              }).then((req: any) => {
                runInAction(() => {
                  this.numberOfPeople = req?.data?.total;
                });
                return req;
              });
            },
          },
        };
        break;
      }
    }
    this.mainSubStructureModel = new MainSubStructureModel(mainSubStructureParams);
    this.mainSubStructureModel.onQuery();
  };
}
