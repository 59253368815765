import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Form, Radio, Space } from 'antd';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 企业信息
 */
@inject('store')
@observer
class CompanyInfo extends React.Component<{ store?: AddModel }> {
  render() {
    const { addressDict, formRef, getCompanyInfo, jobType, companyEditorModalModel, type } = this.props.store;
    const hrUserId = formRef?.current?.getFieldValue('hrUserId');
    if (!hrUserId) {
      return null;
    }
    return (
      <Card
        title="企业信息"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Form.Item
            label="公司名称"
            className={'mb-0'}
            rules={[{ required: jobType === 1 }]}
            shouldUpdate={true}
          >
            {({ getFieldValue }) => {
              const companyName = getFieldValue('companyName');
              const companyId = getFieldValue('companyId');
              return (
                <Space
                  onClick={() => {
                    if (type === 2) {
                      return;
                    }
                    companyEditorModalModel.onOpen(companyId);
                    runInAction(() => {
                      companyEditorModalModel.saveCallBack = () => {
                        getCompanyInfo(companyId);
                      };
                    });
                  }}
                >
                  <Form.Item
                    className={'hidden'}
                    name={'companyId'}
                  />
                  <Form.Item
                    className={'hidden'}
                    name={'companyName'}
                  />
                  <div>{companyName || '-'}</div>
                  {type !== 2 ? <EditOutlined className={'text-[#3A6EF8]'} /> : undefined}
                </Space>
              );
            }}
          </Form.Item>

          <Form.Item
            className={'mb-0'}
            label="工作地址"
            required={true}
            wrapperCol={{ span: 22 }}
          >
            <Space
              className={'w-full'}
              wrap={true}
            >
              <Form.Item
                noStyle={true}
                className={'mb-0'}
                rules={[{ required: true, message: '请选择工作地址！' }]}
                name="companyAddressId"
              >
                <Radio.Group options={addressDict} />
              </Form.Item>
              <Button
                onClick={() => {
                  const companyId = formRef?.current?.getFieldValue('companyId');
                  const hrUserId = formRef?.current?.getFieldValue('hrUserId');
                  companyEditorModalModel.addressModal.onOpen(hrUserId);

                  runInAction(() => {
                    companyEditorModalModel.addressModal.saveCallBack = () => {
                      getCompanyInfo(companyId);
                    };
                  });
                }}
                color="primary"
              >
                + 新增地址
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Card>
    );
  }
}
export default CompanyInfo;
